import { select } from 'd3'

function tooltip (event, data, type, textAlign = 'left', classes = '') {
  let string = ''
  const tooltipHtml = document.querySelector('#common-tooltip')
  switch (type) {
    case 'in':
      if (typeof data === 'object') {
        for (const object in data) {
          string += `<span>${object.length ? object + ':' : ''} ${data[object]}</span><br>`
        }
      } else {
        string = data
      }
      select('#common-tooltip')
        .html(`${string}`)
        .style('font-family', 'Quicksand')
        .style('top', `${getTopPosition(event, tooltipHtml)}px`)
        .style('left', `${getLeftPosition(event, tooltipHtml)}px`)
        .attr('class', function (d) {
          return select(this).attr('class') + ' ' + classes
        })
        .style('display', 'inline')
        .style('text-align', textAlign)
      break
    case 'out':
      select('#common-tooltip').style('display', 'none')
      if (classes.length) {
        classes.split(' ').forEach(className => { tooltipHtml.classList.remove(className) })
      }
      break
  }
}

function getTopPosition (event, tooltipHtml) {
  const topNavHeight = 55
  const arrowHeight = 10
  const tooltipHeight = select('#common-tooltip').node().getBoundingClientRect().height + arrowHeight
  let top = 0
  const halfWidth = tooltipHtml.getBoundingClientRect().width / 2
  if ((event.pageY - topNavHeight) < tooltipHeight) {
    tooltipHtml.classList.remove('bottom-arrow')
    tooltipHtml.classList.remove('right-arrow')
    tooltipHtml.classList.remove('right-bottom-arrow')
    tooltipHtml.classList.remove('right-top-arrow')
    tooltipHtml.classList.add('top-arrow')
    top = event.pageY + 25
  } else {
    tooltipHtml.classList.remove('top-arrow')
    tooltipHtml.classList.remove('right-arrow')
    tooltipHtml.classList.remove('right-bottom-arrow')
    tooltipHtml.classList.remove('right-top-arrow')
    tooltipHtml.classList.add('bottom-arrow')
    top = event.pageY - tooltipHtml.getBoundingClientRect().height - 10
  }
  if ((window.innerWidth - event.pageX) < halfWidth) {
    top = event.pageY - (tooltipHtml.getBoundingClientRect().height / 2)// - 10
  }
  return top
}

function getLeftPosition (event, tooltipHtml) {
  const tooltipHeight = select('#common-tooltip').node().getBoundingClientRect().height + 10
  const halfWidth = tooltipHtml.getBoundingClientRect().width / 2
  const topNavHeight = 55
  if ((window.innerWidth - event.pageX) > halfWidth) {
    return event.pageX - tooltipHtml.getBoundingClientRect().width / 2
  } else {
    tooltipHtml.classList.remove('top-arrow')
    tooltipHtml.classList.remove('bottom-arrow')
    tooltipHtml.classList.remove('right-bottom-arrow')
    tooltipHtml.classList.remove('right-top-arrow')
    tooltipHtml.classList.add('right-arrow')
    if ((window.innerHeight - event.pageY) < (tooltipHeight / 2)) {
      // return event.pageY
      tooltipHtml.classList.remove('right-arrow')
      tooltipHtml.classList.add('right-bottom-arrow')
      tooltipHtml.style.top = `${event.pageY - tooltipHtml.getBoundingClientRect().height - 10}px`
      return event.pageX - tooltipHtml.getBoundingClientRect().width - 10
    } else if ((event.pageY - (topNavHeight)) < ((tooltipHtml.getBoundingClientRect().height / 2) + 10)) {
      tooltipHtml.classList.remove('top-arrow')
      tooltipHtml.classList.remove('bottom-arrow')
      tooltipHtml.classList.remove('right-bottom-arrow')
      tooltipHtml.classList.add('right-top-arrow')
      tooltipHtml.style.top = `${event.pageY + 10}px` // tooltipHtml.getBoundingClientRect().height - 10
      return event.pageX - tooltipHtml.getBoundingClientRect().width - 10
    }
    return event.pageX - tooltipHtml.getBoundingClientRect().width - 10
  }
}

export default tooltip
